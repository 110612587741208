import Image from 'next/image';
import type {NextComponentType} from 'next';
const NavHeader: NextComponentType = () => {
	return (
		<>
			<header id='header' className='sticky top-0 z-50 bg-white border-b-[1px]'>
				<div className='innerDiv zeroVerticalPad mx-auto'>
					<div className='flex justify-between py-[7px] lg:py-[10px]'>
						<Image src='/logo.png' alt='Logo' className='logo' width={127} height={44} />
						<a href='' className='md:px-12 px-9 py-2 inline-block rounded-md bg-primary text-lg'>
							login
						</a>
					</div>
				</div>
			</header>
		</>
	);
};
export default NavHeader;
