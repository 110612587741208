import '../styles/globals.css';
import NavHeader from '../components/NavHeader';
import type {AppProps} from 'next/app';
import Footer from '../components/Footer';
import Head from 'next/head';
function MyApp({Component, pageProps}: AppProps) {
	return (
		<>
		<Head>
			<title>Campus | In your own space and time</title>
			</Head>
			<NavHeader />
			<main className=''>
				<Component {...pageProps} />
			</main>
			<Footer />
		</>
	);
}

export default MyApp;
